import React from 'react';
import { Link } from 'react-router-dom';

function Projects() {
  return (
    <div>
        <div className="flex flex-row items-center">
          <div className=" flex-grow-0 font-sans text-slate-400 font-medium py-10 pr-4 md:text-xl text-lg">Projects</div>
          <div className=" flex-grow h-[1px] bg-slate-700"></div>
        </div>

        <div className='py-2'>
          <div className="py-1 font-sans text-teal-300 font-medium md:text-xl text-lg"><Link to="/projects/music-genre-recognition">Music Genre Recognition</Link></div>
          <div className='py-1 font-sans text-slate-400'>
            <div className='md:w-1/2'>
              A web application which predicts the genre for a song selected by the user. The application allows users to search for a song, and once a particular song is selected, user is presented with the true and predicted genres of the song.
            </div>
          </div>
        </div>

    </div>
  );
}

export default Projects;