
function About() {
    return (
      <div className="flex-grow bg-slate-900 text-slate-200">
      <div id="Title" className="">About</div>
      <div id="Intro" className="">
        {/* Hi, I’m Anand Krishna, a Backend Developer with a passion for machine learning, and data science. 
        With a master’s in Statistics and Computing from Banaras Hindu University, 
        I’ve spent the last few years blending my technical skills with my love for exploration—both of data and the cosmos. */}
      </div>
      <div >

      </div>

      </div>
    );
  }
  
  export default About;
  