import './App.css'
import {Route,Routes } from 'react-router-dom'
import Home from './pages/home'
import About from './pages/about'
import NotFound from './pages/notfound'
import Header from './components/header'
import Footer from './components/footer'
import MGR from './pages/music_genre_recognition'

function App() {
  return (
    <div className='flex flex-col min-h-screen'>
    <Header/>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/home" element={<Home />}/>
      <Route path="/projects/music-genre-recognition" element={<MGR />}/>
      <Route path="/about" element={<About />}/>
      <Route path="*" element={<NotFound />}/>
    </Routes>
    <Footer/>
    </div>
  );
}

export default App;