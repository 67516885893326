import React from 'react';

function Skills() {
  return (
    <div>
        <div className="flex flex-row items-center">
          <div className=" flex-grow-0 font-sans text-slate-400 font-medium py-10 pr-4 md:text-xl text-lg">Technologies I work with</div>
          <div className=" flex-grow h-[1px] bg-slate-700"></div>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className="font-mono text-teal-300">Languages :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">Python, R, JavaScript</span>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className=" font-mono text-teal-300">Frameworks :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">React.js, Express.js, Flask, TensorFlow, Scikit-Learn, Pandas, Matplotlib</span>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className=" font-mono text-teal-300">Machine Learning :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">Supervised and Unsupervised Learning, NLP, Pattern Recognition</span>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className=" font-mono text-teal-300">Tools :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">Git, Jupyter Notebook, VS Code, PyCharm</span>
        </div>
    </div>
  );
}

export default Skills;