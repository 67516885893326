import React from 'react';
import { Link } from 'react-router-dom';

function MGR() {
  return (
    <div className="flex-grow bg-slate-900 text-slate-200">
        <div className="mx-auto w-3/4">
            <div className="text-center font-sans py-4 font-medium md:text-3xl text-2xl">Music Genre Recognition</div>
            <div className='text-center font-mono font-medium md:text-xl text-teal-300'>using a carefully designed machine learning model.</div>
            <div className="flex items-center justify-center py-10">
                <a href="https://music-genre-recognition-1.onrender.com/" target="_blank">
                    <button
                    className="border-2 border-teal-300 p-2 mx-4 mb-1 font-mono text-teal-300 
                    shadow-teal-300 shadow-md 
                    hover:shadow-sm hover:shadow-teal-300">
                    Try it!
                    </button>
                </a>
                <a href="https://github.com/AnandKri/Music-Genre-Recognition" target="_blank">
                    <button
                    className="border-2 border-teal-300 p-2 mx-4 mb-1 font-mono text-teal-300 
                    shadow-teal-300 shadow-md 
                    hover:shadow-sm hover:shadow-teal-300">
                    Git repo
                    </button>
                </a>
            </div>
            <div className='py-4 md:w-1/2'>
                <div className='py-1 text-slate-400 font-sans font-medium md:text-xl text-lg'>Brief Overview</div>
                <div className='py-1 text-slate-200 font-sans font-medium'>The Music Genre Recognition (MGR) project is a web application which predicts the genre for a song selected by the user. The application allows users to search for a song, and once a particular song is selected, user is presented with the true and predicted genres of the song.</div>
            </div>
            <div className='py-4 md:w-1/2 md:float-right'>
                <div className='py-1 text-slate-400 font-sans font-medium md:text-xl text-lg'>Problem Addressed</div>
                <div className='py-1 text-slate-200 font-sans font-medium'>Music artists and streaming services annotate the genre of the music manully. Most of the time, the artists decide the genre of the song which they had created. Through this project I've tried to explore that to what extent does the music itself decides the genre of a song.</div>
            </div>
        </div>
    </div>
  );
}

export default MGR;