import React from 'react';

function Introduction() {
  return (
    <div>
        <div className="font-mono text-teal-300 py-1">Hi, I'm</div>
        <div className="font-sans py-4 font-medium md:text-6xl text-4xl">Anand Krishna.</div>
        <div className="py-2">
            <div className="md:text-3xl text-xl font-medium font-sans text-slate-300">Crafting business solutions through <span className="text-teal-300">code</span> and <span className="text-teal-300">creativity.</span></div>
        </div>
        <div className="font-sans py-4 font-medium md:text-xl md:w-3/4 text-slate-400">Developer with a passion for <span className="text-teal-300">problem-solving, </span>building impactful <span className="">applications, </span> and exploring innovative <span className="">technologies.</span></div>
    </div>
  );
}

export default Introduction;