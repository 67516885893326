import React, { useEffect, useState } from 'react';
import axios from 'axios';
import music from '../icons/music.svg'
import LoadingIcons from 'react-loading-icons'

const Listening = () => {
  const [songName, setSongName] = useState(null);
  const [cover, setCover] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    const url = process.env.REACT_APP_BACKEND_URL;
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/`); // Replace with your backend endpoint
        // const songName = response.data.recenttracks.track[0].album["#text"]
        const songName = response.data.recenttracks.track[0].name
        const cover = response.data.recenttracks.track[0].image[2]["#text"]
        try{
          const listening = response.data.recenttracks.track[0]["@attr"].nowplaying
          setIsListening(listening)
        } catch {
          setIsListening(false)
        }
        setSongName(songName);
        setCover(cover);
      } catch (err) {
        setError(err); // Set error if there's an issue with fetching
      } finally {
        setLoading(false); // Turn off loading state
      }
    };

    fetchData(); // Call the function to fetch data
  }, []); // Empty dependency array means it runs only on component mount

  
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className='bg-slate-900 text-center py-2'>
      {
        loading ? (
          <LoadingIcons.Bars height={"64"} className='mx-auto'/>
        ):(
          <div>
            <img src={music} 
                    alt='music' 
                    className={`px-2 w-20 ${isListening ? 'animate-spin' : ''} animate-spin inline-block`}
                    />
            <img src={cover} width="80px" className='mx-auto px-2 inline-block'/>
            <div className='px-2 inline-block text-white font-semibold'>
              {JSON.stringify(songName, null, 2)}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Listening;
