import React from 'react';
import profile from "../icons/profile.png"

function Experience() {
  return (
    <div>
        <img className="border-b-2 border-l-2 border-teal-300 md:w-1/3 w-1/2 mx-auto my-5" src={profile} />
        <div className="md:inline-block mx-auto">
          <div className="flex flex-row items-center">
            <div className=" flex-grow-0 font-sans text-slate-400 font-medium py-5 pr-4 md:text-xl text-lg">Work Experience</div>
            <div className=" flex-grow h-[1px] bg-slate-700"></div>
          </div>
          <div className="columns-2">
            <div className="font-sans text-teal-300 font-medium md:text-xl text-lg">Genpact</div>
            <span className="font-mono text-teal-300 float-right">'23 - present</span>
          </div>
          <div className="font-sans text-slate-300 font-medium md:text-lg">Python Developer & Data Scientist</div>
            <div className="py-1 font-sans text-slate-400">
              <div className="py-1">Automated <span className="text-teal-300">data extraction</span> and reporting processes using Python and <span className="text-teal-300">Selenium</span>, significantly reducing execution time and manual interventions.</div>
              <div className="py-1">Conducted <span className="text-teal-300">exploratory data analysis</span> on troubleshooting data, enabling downtime prevention and informed decision-making.</div>
              <div className="py-1">Developed and optimized Python scripts for tasks like ticket generation and <span className="text-teal-300">text-to-speech</span> applications</div>
            </div>
        </div>
    </div>
  );
}

export default Experience;