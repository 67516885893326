// import profile from "../icons/profile.png"
import Listening from "../components/listening"
import Introduction from "../components/introduction";
import Skills from "../components/skills";
import Experience from "../components/experience";
import Projects from "../components/projects";

function Home() {
  return (
    <div className="flex-grow bg-slate-900 text-slate-200">

      <Listening/>

      <div className="py-20 mx-auto w-3/4">
        <Introduction/>
      </div>
      
      <div className="flex items-center justify-center py-10">
          <a href={`/pdfs/Anand Krishna.pdf`} without rel="noopener noreferrer" target="_blank">
            <button 
            className="border-2 border-teal-300 p-2 mb-1 font-mono text-teal-300 
            shadow-teal-300 shadow-md 
            hover:shadow-sm hover:shadow-teal-300" 
            trailingIcon="picture_as_pdf" 
            label="Resume">
              Resume
            </button>
          </a>
      </div>

      <div className="mx-auto w-3/4 py-20 md:columns-2">
        <Experience/>
      </div>

      <div className="py-10 mx-auto w-3/4">
        <Projects/>
      </div>

      <div className="py-20 mx-auto w-3/4">
        <Skills/>
      </div>
    </div>
  );
}

export default Home;
